import { EuiButtonEmpty, EuiPopover, EuiText } from "@elastic/eui";
import { navigate } from "gatsby-link";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useState } from "react";
import { useStore } from "../../stores";
import HailCXSingleButtonFooter from "../layout/footer/hailcx-single-button-footer";
import WaitlistContextualHelp from "./waitlist-contextual-help";


const WaitlistActionFooter = () => {

    const { customerStore } = useStore();    
    const {addCustomerToWaitlist, removeCustomerFromWaitlist, isCustomerWaitlisted} = customerStore;
    const [hasCustomerBeenAddedToWaitlist, setCustomerHasBeenAddedToWaitlist] = useState(false);
    const [isWaitlistToggleOperationProcessing, setWaitlistToggleOperationProcessing] = useState(false);

    useEffect(() => {
        checkCustomerWaitListStatus();     
    }, [])


    const checkCustomerWaitListStatus = async () => {
        const result = await isCustomerWaitlisted();
        setCustomerHasBeenAddedToWaitlist(result as boolean);
    }

    const handleWaitlistToggleButtonClick = async () => {
        
        setWaitlistToggleOperationProcessing(true);
  
        if(hasCustomerBeenAddedToWaitlist)
        {
          await removeCustomerFromWaitlist();
          setCustomerHasBeenAddedToWaitlist(false);
        }else{
          await addCustomerToWaitlist();
          setCustomerHasBeenAddedToWaitlist(true);
        }
  
        setWaitlistToggleOperationProcessing(false);
      }  

    const getFooterContent = () => {    
        if(hasCustomerBeenAddedToWaitlist)
        {                
            return(
                <HailCXSingleButtonFooter
                    buttonContent="No, take me off the Waitlist"
                    isLoading={false}
                    handleClick={handleWaitlistToggleButtonClick}
                    legend="Added to waitlist"
                    iconType='clock'
                    iconSide='left'
                    color='danger'
                    isDisabled = {isWaitlistToggleOperationProcessing}
                    />
            );
        }else{
            return(        
                <HailCXSingleButtonFooter
                buttonContent="Yes, put me on the Waitlist"
                isLoading={false}
                handleClick={handleWaitlistToggleButtonClick}
                legendComponent = {<WaitlistContextualHelp />}
                iconType='clock'
                iconSide='left'
                color='success'
                isDisabled = {isWaitlistToggleOperationProcessing}
                />
            );                
        }        
    }

    return(
        getFooterContent()
    );

}

export default WaitlistActionFooter;