import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiPanel, EuiSpacer, EuiText } from "@elastic/eui"
import { getBookingDateTimeUIFormatted, getBookingDateUIFormatted, getBookingTimeUIFormatted, saveEntryPointUrl } from "../../entities/utilities"
import HailCXSingleButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"
import WaitlistActionFooter from "../../components/booking/waitlist-action-footer"


//1. extract customer id from url
//2. Get current booking of customer by customer id
//3. Get available slots by customer id

const Waitlist = () => {

    const { bookingStore, customerStore, waitlistStore, facilityStore } = useStore()

    const {
        //availableSlots,
        booking,
        currentBooking,
        timeSelected,
        //loadAvailableSlots,
        setBooking,
        setTimeSelected,
      } = bookingStore

      const {
        availableSlots,
        loadAvailableSlotsForWaitlistedCustomer,
        lockSlot,
      } = waitlistStore

    const location = useLocation()
    const queriedString = queryString.parse(location.search)
    const { eqn, customerId } = queriedString
    const {facility} = facilityStore
    
    const data = {
        title: "Waitlist",
        primaryContent: "Choose this drop off time",
        secondaryContent: "Keep my current booking",
      }    

    const [scrollBookingSlotToView, setScrollBookingSlotToView] = useState(true);
    const [hasDataBeenLoaded, setDataHasBeenLoaded] = useState(false);  
    const [showAlreadyTakenMessage, setShowAlreadyTakenMessage] = useState(false);


    useEffect(() => {
        //as this page is the entry point of waitlist offer process, save url as entry point url
        saveEntryPointUrl();
      }, [])
    
    useEffect(() => {
        if (eqn) {
          customerStore.setCustomerEncryptedQuoteNumber(
            eqn as string
          )
        }
        if (customerId && !booking) {
            bookingStore.setCustomerId(customerId as string);
            bookingStore.loadCurrentBookingOfCustomer();
        }

        if (!customerStore.customer) {
            customerStore.loadCustomer().then(() => {
                facilityStore.loadFacilityDetails(customerStore.customer?.registeredAtFacilityId);
                renderAvailableSlots();
            });
        }

        setDataHasBeenLoaded(true); 

    }, [waitlistStore])


    const renderAvailableSlots = async () => {        
        if(customerStore.customer)
        {
            await loadAvailableSlotsForWaitlistedCustomer(customerStore.customer.id)
        }          
    };

    const lockSelectedSlot = async () => {
      console.log('in slot selection screen')
      let customerId = customerStore.customer?.id as string;
      let facilityId = facility?.id as string;
      let repairDate = booking?.repairDate as string;
      let dropOffTime = booking?.dropOffTime as string;
      let repairType = booking?.repairType as string;

      await lockSlot(
        {customerId: customerId, 
        facilityId: facilityId, 
        repairDate: repairDate,
        dropOffTime: dropOffTime,
        repairType: repairType
      });  
    }

    const handlePrimaryClick = async () => {
        if (bookingStore.customerId && booking) {
            setBooking({ ...booking, customerId: bookingStore.customerId as string })
            await lockSelectedSlot();
            //debugger;
            if(waitlistStore.lockSlotResponse)
            {
              if(waitlistStore.lockSlotResponse.status === "AlreadyLocked")
              {
                console.log('slot already locked');
                setShowAlreadyTakenMessage(true);     
                return;
              }else if(waitlistStore.lockSlotResponse.status === "NewLockCreated")
              {
                navigate("/book/waitlist-confirm-rescheduled")
              }
            }
          }
    }

    const closeModal = async () => {
      setShowAlreadyTakenMessage(false);
      setDataHasBeenLoaded(false);
      setTimeSelected(''); //clear the selected time to disable the button
      await renderAvailableSlots();
      setDataHasBeenLoaded(true);
    }

    const alreadyTakenMessageModal = () => {
      return(
        <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h1>No longer available</h1>
              </EuiModalHeaderTitle>
            </EuiModalHeader>
        
            <EuiModalBody>
              <p>Sorry! this slot is no longer available as someone has already selected it.</p>
              <EuiSpacer />         
            </EuiModalBody>
        
            <EuiModalFooter>
              <EuiButton onClick={closeModal} fill>
                Ok
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        )
    }


    const handleSecondaryClick = () => {
      navigate("/book/waitlist-current-booking")
    }

    
    const handleBadgeClick = (id: string, value: string, parent: string) => {
    
        //don't scroll when user is selecting the slot; we want to scroll to view only when user comes back to this screen from another screen
        setScrollBookingSlotToView(false); 
        
        setTimeSelected(id)
    
        waitlistStore.setSelectedOfferItem(id)

        if (booking) {
          setBooking({ ...booking, repairDate: parent, dropOffTime: value })
        }    
      }

      const assignColor = (id: string) => {
        if (id === timeSelected) {
          return "success"
        } else {
          return "default"
        }
      }

      const getFooterContent = () => {
        if (availableSlots?.length === 0) 
        {
          return (<WaitlistActionFooter />);
        }else{
            return (
                <HailCXDoubleButtonFooter
                primaryContent={data.primaryContent}
                primaryButtonDisabled={timeSelected === ""}
                secondaryContent={data.secondaryContent}
                handlePrimaryClick={handlePrimaryClick}
                handleSecondaryClick={handleSecondaryClick}
                primaryButtonColor="primary"
                secondaryButtonColor="danger"
                //secondaryButtonDisabled={isCancelled}
                />
            );            
        }        
      }


      const spinnerContent = (
        <>
        <EuiSpacer />
        <EuiSpacer />
        <EuiSpacer />
        <EuiSpacer />
        <EuiFlexGroup
          wrap
          responsive={false}
          gutterSize="s"
          justifyContent="spaceAround"
        >
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size="xl" />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiSpacer />
        <EuiSpacer />
        <EuiSpacer />
      </>
      )

    return(
      <>
        <HailCXLayout
            header={<HailCXRightImageHeader title={data.title} progressValue={0} vehicleInfo={customerStore.getVehicleInfo()} />}
            footer={   
                        getFooterContent()                     
                }
    >
      {(hasDataBeenLoaded) ? (
        <>
        <EuiText 
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}        
        >
            <p>
                <h2>Your current booking</h2>
                <strong>Drop off:                                      
                {currentBooking && ` ${getBookingDateTimeUIFormatted(
                    currentBooking?.repairDate || '',
                    currentBooking?.dropOffTime || ''
                )}`}</strong>
            </p>
        </EuiText>
        <EuiSpacer />
        <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h2>Your new offers</h2>
        <p>Choose an earlier drop-off time.</p>
      </EuiText>     
      {(availableSlots?.length === 0) && 
        <>
        <EuiSpacer />        
          <EuiText
            grow={false}
            className="eui-textLeft"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <p>Unfortunately all booking options have been selected by other customers.</p>
            <p>
            Please confirm if you wish to be sent further SMS notifications when new booking options become available.  
            Otherwise you can come back to this page anytime by clicking the link in your SMS text.
            </p>
          </EuiText>          
          </>     
      }
        {(availableSlots && availableSlots.length > 0) ? (
        availableSlots.map(slot => (
            <div key={slot.repairDate} ref={ele => {
                if (
                    ele &&
                    slot.repairDate === booking?.repairDate
                    &&
                    scrollBookingSlotToView
                ) {
                    ele.scrollIntoView({behavior: "smooth"}); //for some reason this is working in new booking/reschedule booking selection screen but not here??
                }
            }}>
            <EuiSpacer />
            <EuiPanel>
              <EuiText
                grow={false}
                className="eui-textCenter"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                <h3>{getBookingDateUIFormatted(slot.repairDate)}</h3>
              </EuiText>
              <EuiSpacer />
              <EuiFlexGroup
                wrap
                responsive={false}
                gutterSize="s"
                justifyContent="spaceEvenly"
              >      
                {slot.dropOffTimes?.map((time, index) => (
                  <EuiFlexItem key={`${slot.repairDate}-${index}`} grow={false}>
                    <EuiBadge
                      id={`${slot.repairDate}-${index}`}
                      color={assignColor(`${slot.repairDate}-${index}`)}
                      onClick={event => {
                        handleBadgeClick(
                          event.currentTarget.id,
                          event.currentTarget.dataset.time ?? "",
                          slot.repairDate
                        )
                      }}
                      onClickAriaLabel={time}
                      data-time={time}
                    >
                      {getBookingTimeUIFormatted(time)}
                    </EuiBadge>
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiPanel>
          </div>
        ))
      ) : (
        <>        
        </>
      )}
      </>
      ) :
      (spinnerContent)
    }
        </HailCXLayout>

    {
      showAlreadyTakenMessage && alreadyTakenMessageModal()
    }
    </>
    

    )

}

export default observer(Waitlist)