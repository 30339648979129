import React, { useState } from 'react';
import {
  EuiImage,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiDescriptionList,
  EuiText
} from '@elastic/eui';
import Images from '../../images';

interface Props {

}

const WaitlistContextualHelp = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const phoneNumber = '1300 395 395';

  const helpContent = [
    {
      title: <EuiImage src={Images.User} alt="" size="32px" />,
      description: "Ask one of our Technicians for help.",
    },
    {
      title: <EuiImage src={Images.Phone} alt="" size="32px" />,
      description: (<>Alternatively, please call our friendly Customer Service team on <strong><a href={'tel:' + phoneNumber.replace(/ /g, '')}>{phoneNumber}</a></strong></>),
    }
  ]

  return (
    <>
      <EuiButtonEmpty
        size="s"
        color="text"
        onClick={() => {
          showModal()
        }}
      >
        Want your repair sooner?
      </EuiButtonEmpty>

      {isModalVisible &&
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Want your repair sooner?</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
          <EuiText>
          <p>Invariably people will drop out of their allocated slots which can open earlier dates. 
            By clicking the below 'Send waitlist offers via SMS' button, if an earlier appointment comes available, we will send you an offer via SMS</p>
        </EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty
              size="s"
              color="text"
              onClick={closeModal}
            >
              {"x"} Close
            </EuiButtonEmpty>
          </EuiModalFooter>
        </EuiModal>}
    </>
  );
}

export default WaitlistContextualHelp;